@tailwind base;
@tailwind components;
@tailwind utilities;


.filter-component {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .filter-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    justify-content: space-between;
  }
  
  .filter-label {
    margin-right: 10px;
    color: #62646A99;
  }
  
  .filter-control {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .filter-control button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .filter-control label {
    display: flex;
    align-items: center;
  }
  
  .filter-control input[type="radio"] {
    margin-right: 5px;
    accent-color: black;
  }